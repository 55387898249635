<template>
  <div class="ohgroup-hours">
    <div class="ohgroup-hours__wrapper" v-for="(day, idx) in weekdays" :key="`day-${day.key}-${idx}-${index}`">
      <div class="ohgroup-hours__wrapper__block">
        <div class="ohgroup-hours__wrapper__block__day-mobile">{{ $t(`common.days.${day.key}`).slice(0, 3) }}.</div>
        <div class="ohgroup-hours__wrapper__block__day">
          {{ $t(`common.days.${day.key}`) }}
        </div>
        <ui-switch
          class="ohgroup-hours__wrapper__block__status"
          :id="`switch-${day.key}-${index}`"
          v-model="day.open"
          @input="toggleSlots(day)"
        >
          <template v-if="day.open">
            {{ $t('common.label.open') }}
          </template>
          <template v-else>
            {{ $t('common.label.closed') }}
          </template>
        </ui-switch>
        <ui-checkbox
          class="ohgroup-hours__wrapper__block__h24"
          :id="`checkbox-h24-${day.key}-${index}`"
          v-model="day.h24"
          v-if="day.open"
          @input="toggleSlots(day)"
        >
          {{ $t('common.label.h24') }}
        </ui-checkbox>
      </div>
      <div class="ohgroup-hours__wrapper__slots" v-if="day.open && !day.h24">
        <div
          class="ohgroup-hours__wrapper__slots__line"
          v-for="(slot, idxSlot) in day.slots"
          :key="`slot-${day.key}-${idxSlot}-${index}`"
        >
          <ui-dropdown
            class="required-asterisk ohgroup-hours__wrapper__slots__line__input"
            :id="`dropdown-opening-${day.key}-${idx}-${idxSlot}-${index}`"
            :key="`dropdown-opening-${day.key}-${idx}-${idxSlot}-${index}`"
            :options="hoursOptions"
            :show-caret="false"
            :placeholder="$t('common.label.opening')"
            :error="$v.weekdays.$each[idx].slots.$each[idxSlot].opening.$error"
            :no-results-label="$t('modal.hours.dropdown.noResult')"
            :value="slot.opening"
            @input="value => (slot.opening = value.name)"
          >
            <template
              v-slot:helper
              v-if="
                $v.weekdays.$each[idx].slots.$each[idxSlot].opening.$error &&
                !$v.weekdays.$each[idx].slots.$each[idxSlot].opening.required
              "
            >
              {{ $t('errors.required') }}
            </template>
            <template v-slot:option="{ option }">
              {{ option.name }}
            </template>
          </ui-dropdown>
          <ui-dropdown
            class="required-asterisk ohgroup-hours__wrapper__slots__line__input"
            :id="`dropdown-closing-${day.key}-${idx}-${idxSlot}-${index}`"
            :key="`dropdown-closing-${day.key}-${idx}-${idxSlot}-${index}`"
            :options="hoursOptions"
            :show-caret="false"
            :placeholder="$t('common.label.closing')"
            :error="$v.weekdays.$each[idx].slots.$each[idxSlot].closing.$error"
            :no-results-label="$t('modal.hours.dropdown.noResult')"
            :value="slot.closing"
            @input="value => (slot.closing = value.name)"
          >
            <template
              v-slot:helper
              v-if="
                $v.weekdays.$each[idx].slots.$each[idxSlot].closing.$error &&
                !$v.weekdays.$each[idx].slots.$each[idxSlot].closing.required
              "
            >
              {{ $t('errors.required') }}
            </template>
            <template v-slot:option="{ option }">
              {{ option.name }}
            </template>
          </ui-dropdown>
          <ui-button
            class="ohgroup-hours__wrapper__slots__line__cta"
            :label="$t('modal.hours.button.addSlot')"
            button="secondary"
            icon="add_hours"
            :iconOnly="true"
            v-if="idxSlot === 0"
            :disabled="
              !day.slots[day.slots.length - 1].opening ||
              !day.slots[day.slots.length - 1].closing ||
              day.slots.length === 2
            "
            v-tooltip="{
              placement: 'top',
              trigger: 'hover',
              content: $t('modal.hours.button.addSlot'),
              offset: 3,
            }"
            @click="addSlot(day)"
          />
          <ui-button
            class="ohgroup-hours__grid__wrapper__slots__line__cta"
            :label="$t('modal.hours.button.removeSlot')"
            button="secondary"
            icon="close"
            :iconOnly="true"
            v-if="idxSlot > 0"
            v-tooltip="{
              placement: 'top',
              trigger: 'hover',
              content: $t('modal.hours.button.removeSlot'),
              offset: 3,
            }"
            @click="deleteSlot(day, idxSlot)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiSwitch from '@/components/UI/Switch.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'
import { required } from 'vuelidate/lib/validators'
import { timeArray } from '@/utils/hours.util'

export default {
  name: 'OhGroupHours',
  components: {
    UiSwitch,
    UiCheckbox,
    UiDropdown,
    UiButton,
  },
  props: {
    choosenWeekDays: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      modelData: [],
      hoursOptions: timeArray(),
      weekdays: [],
    }
  },
  mounted() {
    this.weekdays = JSON.parse(JSON.stringify(this.choosenWeekDays))
  },
  methods: {
    addSlot(data) {
      data.slots.push({
        opening: '',
        closing: '',
      })
    },
    deleteSlot(data, index) {
      data.slots.splice(index, 1)
    },
    toggleSlots(item) {
      this.weekdays = this.weekdays.map(day => {
        if (day.key === item.key) {
          return {
            ...day,
            slots:
              day.open && !day.h24
                ? [
                    {
                      opening: '',
                      closing: '',
                    },
                  ]
                : [],
          }
        } else {
          return day
        }
      })
    },
    convertData() {
      const hours = {}
      this.weekdays.map(day => {
        if (!day.open) {
          hours[day.key] = []
        } else if (day.h24) {
          hours[day.key] = ['0:00-0:00']
        } else {
          hours[day.key] = day.slots.map(slot => `${slot.opening}-${slot.closing}`)
        }
      })
      this.modelData.hours = hours
    },
    clearDate(date) {
      this.modelData[date] = ''
    },
  },
  validations() {
    return {
      weekdays: {
        $each: {
          slots: {
            $each: {
              opening: {
                required,
              },
              closing: {
                required,
              },
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.ohgroup-hours {
  margin: 0 (-$gutter-mobile / 2);

  @media (min-width: $screen-sm) {
    margin: 0 (-$gutter-tablet / 2);
  }

  &__wrapper {
    position: relative;
    border-top: 1px solid var(--border-color);
    padding: $gutter-mobile $gutter-mobile / 2;

    @media (min-width: $screen-sm) {
      padding: $gutter-mobile $gutter-tablet / 2;
    }

    @media (min-width: $screen-md) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &:first-child {
      border: none;
    }

    &__block {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: $gutter-mobile;
      align-items: center;

      @media (min-width: $screen-md) {
        grid-template-columns: repeat(3, 1fr);
        padding-top: 13px;
        width: 40%;
      }

      &__day-mobile {
        @media (min-width: $screen-sm) {
          display: none;
        }
      }

      &__day {
        display: none;

        @media (min-width: $screen-sm) {
          display: block;
        }
      }

      &__day,
      &__day-mobile {
        grid-column: 1;
        font-weight: 600;
      }

      &__status {
        grid-column: 2;

        @media (min-width: $screen-sm) {
          justify-self: center;
        }
      }

      &__h24 {
        grid-column: 3;

        @media (min-width: $screen-sm) {
          justify-self: center;
        }
      }
    }

    &__slots {
      @media (min-width: $screen-md) {
        width: 40%;
      }

      &__line {
        display: flex;
        align-items: flex-start;
        margin-top: $gutter-mobile / 2;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet / 2;
        }

        &:first-child {
          @media (min-width: $screen-md) {
            margin-top: 0;
          }
        }

        &__input {
          margin-right: $gutter-mobile / 2;
          width: calc(100% / 2 - (#{$button-min-height} / 2 + #{$gutter-mobile} / 2));

          @media (min-width: $screen-sm) {
            margin-right: $gutter-tablet / 2;
            width: calc(100% / 2 - (#{$button-min-height} / 2 + #{$gutter-tablet} / 2));
          }
        }
      }
    }
  }
}
</style>
