<template>
  <div class="ohgroup-list" @scroll.passive="onScroll($event.srcElement, $refs.fixedElement.$el)">
    <screen-header
      class="ohgroup-list__header"
      :title="
        $tc('ohgroup.list.title', ohGroupsPagination.nbResults, {
          count: ohGroupsPagination.nbResults,
        })
      "
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
    >
      <div class="ohgroup-list__header__create" v-if="isLoading">
        <skeleton-input />
      </div>
      <ui-button
        class="ohgroup-list__header__create"
        button="cta"
        variant="data"
        icon="add"
        :label="$t('ohgroup.list.button.create')"
        :mobile-toggle="true"
        @click="addOhGroup"
        v-else
      />
    </screen-header>
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <div class="ohgroup-list__list">
              <ui-data-list
                :items="ohGroupsMobile"
                :is-loading="isLoading"
                :is-scrolling="isScrolling"
                :pagination="ohGroupsPagination"
                infinite-scroll
                @loadItems="loadOhGroups({ ...$event, page: ohGroupsPagination.page + 1 }, false, true)"
                @click="editOhGroup({ row: { id: $event.id, rowIndex: null } })"
              >
                <template v-slot:skeleton>
                  <skeleton-line height="18px" width="80%" />
                  <skeleton-line height="15px" width="50%" margin-top="3px" />
                </template>
                <template v-slot:item="slotProps">
                  <div class="ohgroup-list__list__content">
                    {{ slotProps.item.id }} - {{ slotProps.item.name }}
                    <br />

                    <div class="tw-flex tw-gap-1 tw-mt-1">
                      <template v-for="(country, idx) in slotProps.item.countries">
                        <v-img
                          v-if="getFlagSrc(country)"
                          :src="getFlagSrc(country)"
                          :key="`img-country ${idx}`"
                          max-width="24"
                        />
                        <span v-else :key="`span-country ${idx}`">
                          {{ country }}
                        </span>
                        <span v-if="idx < slotProps.item.countries.length - 1" :key="`length-country ${idx}`">-</span>
                      </template>
                    </div>
                  </div>
                  <span
                    class="ohgroup-list__list__status backoffice-icons"
                    :class="[`ohgroup-list__list__status--${slotProps.item.status}`]"
                  >
                    <template v-if="slotProps.item.status === '1'"> success </template>
                    <template v-else>error</template>
                  </span>
                </template>
              </ui-data-list>
            </div>
            <div class="ohgroup-list__table">
              <ui-data-table
                :columns="columns"
                :rows="ohGroups"
                :total-rows="totalRows"
                :is-loading="isLoading || isUpdating"
                :pagination="ohGroupsPagination"
                @loadItems="loadOhGroups({ ...$event }, true, true)"
                @onCellClick="editOhGroup"
              >
                <template slot="data-table-row" slot-scope="props">
                  <span v-if="props.column.field === 'id'">
                    {{ props.row['id'] }}
                  </span>
                  <span v-if="props.column.field === 'name'">
                    {{ props.row['name'] }}
                  </span>
                  <span v-if="props.column.field === 'countries'">
                    <div class="tw-flex tw-gap-1">
                      <template v-for="(country, idx) in props.row['countries']">
                        <v-img
                          v-if="getFlagSrc(country)"
                          :src="getFlagSrc(country)"
                          :key="`img-country ${idx}`"
                          max-width="24"
                        />
                        <span v-else :key="`span-country ${idx}`">
                          {{ country }}
                        </span>
                        <span v-if="idx < props.row['countries'].length - 1" :key="`length-country ${idx}`">-</span>
                      </template>
                    </div>
                  </span>
                  <span v-if="props.column.field === 'status'">
                    <ui-loader
                      v-if="clickedCell === props.row['originalIndex']"
                      width="22"
                      height="22"
                      :strokeWidth="3"
                    ></ui-loader>
                    <template v-else>
                      <span
                        class="ohgroup-list__table__status backoffice-icons"
                        :class="[`ohgroup-list__table__status--${props.row['status']}`]"
                      >
                        <template v-if="props.row['status'] === '1'"> success </template>
                        <template v-else>error</template>
                      </span>
                    </template>
                  </span>
                </template>
              </ui-data-table>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
    <modal-oh-group-hours
      v-if="isEditing && editingModal === 'ohgroup'"
      :is-updating="isUpdating"
      :group="modal.group"
      :subgroups="modal.subgroups"
      :countries="modal.countries"
      :title="modal.title"
      @save="saveOhGroupHours"
      @closed="closedModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { notif } from '@/mixins/notification.mixin'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiButton from '@/components/UI/Button.vue'
import UiLoader from '@/components/UI/Loader.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import ModalOhGroupHours from '@/components/Modal/OhGroupHours.vue'
import { onScroll } from '@/mixins/scroll.mixin'

export default {
  name: 'OhGroupList',
  components: {
    UiDataTable,
    UiDataList,
    UiButton,
    UiLoader,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonInput,
    ModalOhGroupHours,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isScrolling: false,
      isUpdating: false,
      isEditing: false,
      noMoreData: false,
      totalRows: 0,
      clickedCell: null,
      modal: {
        data: null,
        title: null,
      },
      ohGroupCountriesParsed: [],
    }
  },
  async mounted() {
    this.loadOhGroups(null, true)
    await this.getOhGroupCountries()

    this.ohGroupCountriesParsed = this.countries.filter(country =>
      this.ohGroupCountries.some(ohCountry => ohCountry.countryCode === country.countryCode)
    )
  },
  watch: {
    canRefreshData: {
      handler() {
        if (this.canRefreshData && !this.isScrolling && !this.noMoreData) {
          this.isScrolling = true
          this.loadOhGroups({
            page: this.ohGroupsPagination.page + 1,
          })
        }
      },
    },
  },
  computed: {
    ...mapState({
      ohGroups: state => state.ohgroup.ohGroups,
      ohGroupsMobile: state => state.ohgroup.ohGroupsMobile,
      ohGroupsPagination: state => state.ohgroup.ohGroupsPagination,
      currentOhGroup: state => state.ohgroup.currentOhGroup,
      ohSubgroups: state => state.ohgroup.ohSubgroups,
      ohGroupCountries: state => state.ohgroup.ohGroupCountries,
      countries: state => state.backoffice.countries,
    }),
    noData() {
      return this.isLoading || this.noMoreData
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('ohgroup.list.breadcrumb'),
          route: {
            name: 'OhGroup',
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('ohgroup.list.column.id'),
          field: 'id',
          sortable: false,
        },
        {
          label: this.$t('ohgroup.list.column.name'),
          field: 'name',
          sortable: false,
        },
        {
          label: this.$t('ohgroup.list.column.country'),
          field: 'countries',
          sortable: false,
        },
        {
          label: this.$t('ohgroup.list.column.status'),
          field: 'status',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getOhGroups: 'ohgroup/getOhGroups',
      getOhGroup: 'ohgroup/getOhGroup',
      getOhSubgroups: 'ohgroup/getOhSubgroups',
      getOhGroupCountries: 'ohgroup/getOhGroupCountries',
      updateOhGroup: 'ohgroup/updateOhGroup',
      updateOhSubgroup: 'ohgroup/updateOhSubgroup',
    }),
    loadOhGroups(tableParams = null, resetOhGroup = false, updating = false) {
      if (updating) {
        this.isUpdating = true
      } else {
        this.isLoading = true
      }
      this.getOhGroups({ tableParams, resetOhGroup })
        .then(() => {
          this.totalRows = this.ohGroupsPagination.nbResults
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
        .catch(() => {
          this.noMoreData = true
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
    },
    addOhGroup() {
      this.$router.push({ name: 'OhGroupCreate' })
    },
    getFlagSrc(country) {
      try {
        return require(`@/assets/img/flags/${country.toLowerCase()}.svg`)
      } catch {
        return null
      }
    },
    async editOhGroup(payload) {
      this.clickedCell = payload.rowIndex
      await this.getOhGroup({ ohGroupId: payload.row.id })
      await this.getOhSubgroups({ ohGroupId: payload.row.id })
      this.clickedCell = null

      this.modal.group = this.currentOhGroup
      this.modal.subgroups = this.ohSubgroups
      this.modal.countries = this.ohGroupCountriesParsed
      this.modal.title = this.$t('ohgroup.modal.title', {
        group: this.currentOhGroup.name,
      })
      this.isEditing = true
      this.editingModal = 'ohgroup'
      this.$nextTick(() => {
        this.$modal.show('modal-ohgroup-hours')
      })
    },
    async saveOhGroupHours(params) {
      this.isUpdating = true
      try {
        await this.updateOhGroup(params.objKey.group)
      } catch (err) {
        this.isUpdating = false
        this.notificationError()
        return
      }

      try {
        const promises = params.objKey.subgroups.map(subgroup => {
          return this.updateOhSubgroup(subgroup)
        })
        await Promise.all(promises)
        this.loadOhGroups(null, true)
        this.$modal.hide('modal-ohgroup-hours')
        this.closedModal()
        this.notificationSuccess('ohgroup', params.objKey.group.name)
      } catch (err) {
        this.isUpdating = false
        this.notificationError()
      }
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
    },
  },
}
</script>

<style lang="scss">
.ohgroup-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');

      &--1 {
        color: map-get($generic-color-variants, 'success');
      }
    }
  }

  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    &__status {
      color: map-get($generic-color-variants, 'error');

      &--1 {
        color: map-get($generic-color-variants, 'success');
      }
    }
  }
}
</style>
