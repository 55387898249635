<template>
  <modal
    :title="title"
    name="modal-ohgroup-hours"
    :has-apply="true"
    :is-updating="isUpdating"
    :width="900"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <div class="screen-tabs__wrapper__container">
        <div
          class="screen-tabs__wrapper__container__tab"
          :class="{
            'screen-tabs__wrapper__container__tab--selected': currentTab === tab.key,
          }"
          v-for="(tab, idx) in tabs"
          :key="`tab-${idx}`"
          @click="setCurrentTab(tab.key)"
          :style="style"
        >
          {{ tab.label }}
          <div
            class="screen-tabs__wrapper__container__tab__dot screen-tabs__wrapper__container__tab__dot--active"
          ></div>
        </div>
      </div>

      <!-- GROUP Section -->
      <div v-show="currentTab === 'group'" class="modal-ohgroup-hours__group">
        <div class="modal-ohgroup-hours__input">
          <ui-input
            class="required-asterisk modal-ohgroup-hours__input__name"
            v-model.trim="modelData.name"
            :label="$t('ohgroup.modal.group.name')"
            id="modal-ohgroup-hours-name"
            :error="$v.modelData.name.$error"
          >
            <template v-if="$v.modelData.name.$error && !$v.modelData.name.required">
              {{ $t('errors.required') }}
            </template>
          </ui-input>
          <ui-tag
            class="required-asterisk modal-ohgroup-hours__input__countries"
            v-model="modelData.countries"
            id="modal-ohgroup-countries"
            :placeholder="$t('ohgroup.modal.group.tag.placeholder')"
            :tag-label="$t('ohgroup.modal.group.tag.label')"
            :show-label="true"
            label="name"
            track-by="name"
            :options="countries"
            :error="$v.modelData.countries.$error"
            :is-required="true"
          >
            <template v-slot:helper v-if="$v.modelData.countries.$error && !$v.modelData.countries.required">
              {{ $t('errors.required') }}
            </template>
          </ui-tag>
        </div>
        <div class="modal-ohgroup-hours__grid">
          <div
            class="modal-ohgroup-hours__grid__wrapper"
            v-for="(day, idx) in groupWeekdays"
            :key="`day-${day.key}-${idx}`"
          >
            <div class="modal-ohgroup-hours__grid__wrapper__block">
              <div class="modal-ohgroup-hours__grid__wrapper__block__day-mobile">
                {{ $t(`common.days.${day.key}`).slice(0, 3) }}.
              </div>
              <div class="modal-ohgroup-hours__grid__wrapper__block__day">
                {{ $t(`common.days.${day.key}`) }}
              </div>
              <ui-switch
                class="modal-ohgroup-hours__grid__wrapper__block__status"
                :id="`switch-${day.key}`"
                v-model="day.open"
                @input="toggleSlots(day)"
              >
                <template v-if="day.open">
                  {{ $t('common.label.open') }}
                </template>
                <template v-else>
                  {{ $t('common.label.closed') }}
                </template>
              </ui-switch>
              <ui-checkbox
                class="modal-ohgroup-hours__grid__wrapper__block__h24"
                :id="`checkbox-h24-${day.key}`"
                v-model="day.h24"
                v-if="day.open"
                @input="toggleSlots(day)"
              >
                {{ $t('common.label.h24') }}
              </ui-checkbox>
            </div>
            <div class="modal-ohgroup-hours__grid__wrapper__slots" v-if="day.open && !day.h24">
              <div
                class="modal-ohgroup-hours__grid__wrapper__slots__line"
                v-for="(slot, idxSlot) in day.slots"
                :key="`slot-${day.key}-${idxSlot}`"
              >
                <ui-dropdown
                  class="modal-ohgroup-hours__grid__wrapper__slots__line__input"
                  :id="`dropdown-opening-${day.key}-${idx}-${idxSlot}`"
                  :key="`dropdown-opening-${day.key}-${idx}-${idxSlot}`"
                  :options="hoursOptions"
                  :show-caret="false"
                  :placeholder="$t('common.label.opening')"
                  :error="$v.groupWeekdays.$each[idx].slots.$each[idxSlot].opening.$error"
                  :no-results-label="$t('modal.hours.dropdown.noResult')"
                  :value="slot.opening"
                  @input="value => (slot.opening = value.name)"
                >
                  <template
                    v-slot:helper
                    v-if="
                      $v.groupWeekdays.$each[idx].slots.$each[idxSlot].opening.$error &&
                      !$v.groupWeekdays.$each[idx].slots.$each[idxSlot].opening.required
                    "
                  >
                    {{ $t('errors.required') }}
                  </template>
                  <template v-slot:option="{ option }">
                    {{ option.name }}
                  </template>
                </ui-dropdown>
                <ui-dropdown
                  class="modal-ohgroup-hours__grid__wrapper__slots__line__input"
                  :id="`dropdown-closing-${day.key}-${idx}-${idxSlot}`"
                  :key="`dropdown-closing-${day.key}-${idx}-${idxSlot}`"
                  :options="hoursOptions"
                  :show-caret="false"
                  :placeholder="$t('common.label.closing')"
                  :error="$v.groupWeekdays.$each[idx].slots.$each[idxSlot].closing.$error"
                  :no-results-label="$t('modal.hours.dropdown.noResult')"
                  :value="slot.closing"
                  @input="value => (slot.closing = value.name)"
                >
                  <template
                    v-slot:helper
                    v-if="
                      $v.groupWeekdays.$each[idx].slots.$each[idxSlot].closing.$error &&
                      !$v.groupWeekdays.$each[idx].slots.$each[idxSlot].closing.required
                    "
                  >
                    {{ $t('errors.required') }}
                  </template>
                  <template v-slot:option="{ option }">
                    {{ option.name }}
                  </template>
                </ui-dropdown>
                <ui-button
                  class="modal-ohgroup-hours__grid__wrapper__slots__line__cta"
                  :label="$t('modal.hours.button.addSlot')"
                  button="secondary"
                  icon="add_hours"
                  :iconOnly="true"
                  v-if="idxSlot === 0"
                  :disabled="
                    !day.slots[day.slots.length - 1].opening ||
                    !day.slots[day.slots.length - 1].closing ||
                    day.slots.length === 2
                  "
                  v-tooltip="{
                    placement: 'top',
                    trigger: 'hover',
                    content: $t('modal.hours.button.addSlot'),
                    offset: 3,
                  }"
                  @click="addSlot(day)"
                />
                <ui-button
                  class="modal-hours__grid__wrapper__slots__line__cta"
                  :label="$t('modal.hours.button.removeSlot')"
                  button="secondary"
                  icon="close"
                  :iconOnly="true"
                  v-if="idxSlot > 0"
                  v-tooltip="{
                    placement: 'top',
                    trigger: 'hover',
                    content: $t('modal.hours.button.removeSlot'),
                    offset: 3,
                  }"
                  @click="deleteSlot(day, idxSlot)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- SUBGROUP Section -->
      <div v-show="currentTab === 'subgroup'">
        <div class="modal-ohgroup-hours__subgroup" v-for="(sub, idx) in modelDataSub" :key="idx">
          <div class="modal-ohgroup-hours__subgroup__top">
            <ui-input
              class="required-asterisk modal-ohgroup-hours__subgroup__top__name"
              v-model.trim="sub.name"
              :label="$t('ohgroup.modal.subgroup.name')"
              :id="`modal-ohgroup-hours-name${idx}`"
              :error="$v.modelDataSub.$each[idx].name.$error"
            >
              <template v-if="$v.modelDataSub.$each[idx].name.$error && !$v.modelDataSub.$each[idx].name.required">
                {{ $t('errors.required') }}
              </template>
            </ui-input>
            <ui-radio
              class="modal-ohgroup-hours__subgroup__top__default"
              :id="`switch-${sub.name}-${idx}`"
              :value="idx"
              v-model="radioSubgroupsDefault"
              name="radio.subgroups.default"
              @change="toggleDefault(idx)"
            >
              <template>
                {{ $t('common.label.default') }}
              </template>
            </ui-radio>
          </div>
          <oh-group-hours
            class="modal-ohgroup-hours__subgroup__hours"
            :choosen-week-days="subgroupWeekdays[idx]"
            :index="idx"
            :ref="`subgroupHours${idx}`"
          />
        </div>
        <screen-action
          class="ohgroup-create__addbutton"
          :label="$t('ohgroup.modal.subgroup.add')"
          icon="add"
          :is-loading="isUpdating"
          @click="addSubgroup()"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import UiRadio from '@/components/UI/Radio.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'
import UiInput from '@/components/UI/Input.vue'
import UiTag from '@/components/UI/Tag.vue'
import ScreenAction from '@/components/Screen/Action.vue'
import OhGroupHours from '@/components/OhGroup/Hours.vue'
import { required } from 'vuelidate/lib/validators'
import { timeArray } from '@/utils/hours.util'
import { productColor } from '@/config/productColor.config'

export default {
  name: 'ModalOhGroupHours',
  components: {
    UiRadio,
    UiSwitch,
    UiCheckbox,
    UiDropdown,
    UiButton,
    UiInput,
    UiTag,
    ScreenAction,
    OhGroupHours,
  },
  props: {
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    group: {
      type: Object,
      required: true,
    },
    subgroups: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabsElement: null,
      currentTab: 'group',
      modelData: null,
      modelDataSub: [],
      daysArray: [
        {
          key: 'monday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'tuesday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'wednesday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'thursday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'friday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'saturday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'sunday',
          open: false,
          h24: false,
          slots: [],
        },
      ],
      groupWeekdays: [],
      subgroupWeekdays: [],
      hoursOptions: timeArray(),
      radioSubgroupsDefault: 0,
    }
  },
  mounted() {
    this.modelData = Object.assign({}, this.group, {
      ...this.group,
    })
    this.modelDataSub = JSON.parse(JSON.stringify(this.subgroups))

    this.modelData.countries = this.countries.filter(country => this.modelData.countries.includes(country.countryCode))

    this.groupWeekdays = this.generateDays(this.modelData.hours)
    this.groupWeekdays = this.groupWeekdays.filter(day => this.modelData.weekdays.includes(day.key))

    this.modelDataSub.forEach((subgroup, i) => {
      this.subgroupWeekdays[i] = this.generateDays(subgroup.hours)
      this.subgroupWeekdays[i] = this.subgroupWeekdays[i].filter(day => !this.modelData.weekdays.includes(day.key))
      if (subgroup.default === '1') {
        this.radioSubgroupsDefault = i
      }
    })
  },
  computed: {
    style() {
      return {
        '--product-color': productColor[this.productLine],
      }
    },
    productLine() {
      return this.$route.meta.productLine || 'none'
    },
    tabs() {
      return [
        {
          label: this.$t('ohgroup.modal.tabs.group'),
          key: 'group',
        },
        {
          label: this.$t('ohgroup.modal.tabs.subgroup'),
          key: 'subgroup',
        },
      ]
    },
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.convertData()

        if (this.checkSubgroup()) {
          this.$emit('save', {
            objKey: {
              group: this.modelData,
              subgroups: this.modelDataSub,
            },
          })
        }
        this.$v.$reset()
      }
    },
    checkSubgroup() {
      return this.modelDataSub.every((sub, idx) => {
        this.$refs[`subgroupHours${idx}`][0].$v.$touch()
        if (!this.$refs[`subgroupHours${idx}`][0].$v.$invalid) {
          this.$refs[`subgroupHours${idx}`][0].convertData()
          sub.weekdays = this.$refs[`subgroupHours${idx}`][0].weekdays
          sub.hours = this.$refs[`subgroupHours${idx}`][0].modelData.hours
          sub.hours = { ...sub.hours, ...this.modelData.hours }

          this.$refs[`subgroupHours${idx}`][0].$v.$reset()
          return true
        }
        return false
      })
    },
    closed() {
      this.$emit('closed')
    },
    toggleDefault(value) {
      this.modelDataSub.forEach(sub => {
        sub.default = '0'
      })

      this.modelDataSub[`${value}`].default = '1'
    },
    addSlot(data) {
      data.slots.push({
        opening: '',
        closing: '',
      })
    },
    deleteSlot(data, index) {
      data.slots.splice(index, 1)
    },
    toggleSlots(item) {
      this.groupWeekdays = this.groupWeekdays.map(day => {
        if (day.key === item.key) {
          return {
            ...day,
            slots:
              day.open && !day.h24
                ? [
                    {
                      opening: '',
                      closing: '',
                    },
                  ]
                : [],
          }
        } else {
          return day
        }
      })
    },
    generateDays(openingHours) {
      const openingHoursArray = []
      const openingHoursObject = Object.entries(openingHours)

      for (const [day, time] of openingHoursObject) {
        let slots = time.map(t => ({
          opening: t.split('-')[0],
          closing: t.split('-')[1],
        }))
        const h24 =
          slots.length === 1 &&
          (slots[0].opening === '0:00' || slots[0].opening === '00:00') &&
          (slots[0].closing === '0:00' || slots[0].closing === '00:00')
        if (h24) {
          slots = []
        }
        openingHoursArray.push({
          key: day,
          open: slots.length > 0 || h24,
          h24,
          slots,
        })
      }
      return openingHoursArray
    },
    convertData() {
      const hours = {}
      this.groupWeekdays.map(day => {
        if (!day.open) {
          hours[day.key] = []
        } else if (day.h24) {
          hours[day.key] = ['0:00-0:00']
        } else {
          hours[day.key] = day.slots.map(slot => `${slot.opening}-${slot.closing}`)
        }
      })
      this.modelData.hours = hours
    },
    clearDate(date) {
      this.modelData[date] = ''
    },
    addSubgroup() {
      this.modelDataSub.push({
        name: '',
        hours: [],
        weekdays: this.daysArray.filter(day => !this.modelData.weekdays.includes(day.key)),
        default: '0',
      })
      this.subgroupWeekdays.push(this.daysArray.filter(day => !this.modelData.weekdays.includes(day.key)))
    },
    onTabsMounted(element) {
      this.tabsElement = element
    },
    setCurrentTab(tab) {
      this.currentTab = tab
    },
  },
  validations() {
    return {
      groupWeekdays: {
        $each: {
          slots: {
            $each: {
              opening: {
                required,
              },
              closing: {
                required,
              },
            },
          },
        },
      },
      modelData: {
        name: {
          required,
        },
        countries: {
          required,
        },
      },
      modelDataSub: {
        $each: {
          name: {
            required,
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-ohgroup-hours {
  &__group {
    padding: $gutter-mobile/2 $gutter-mobile;

    @media (min-width: $screen-sm) {
      padding: $gutter-tablet/2 $gutter-tablet;
    }
  }
  &__input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      flex-direction: row;
      margin-bottom: $gutter-tablet;
    }

    &__name,
    &__countries {
      @media (min-width: $screen-sm) {
        width: calc(100% / 2 - (#{$gutter-mobile} / 2));
      }
    }
  }

  &__grid {
    margin: (-$gutter-mobile) (-$gutter-mobile / 2);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-mobile) (-$gutter-tablet / 2);
    }

    &__wrapper {
      position: relative;
      border-top: 1px solid var(--border-color);
      padding: $gutter-mobile $gutter-mobile / 2;

      @media (min-width: $screen-sm) {
        padding: $gutter-mobile $gutter-tablet / 2;
      }

      @media (min-width: $screen-md) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      &:first-child {
        border: none;
      }

      &__block {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: $gutter-mobile;
        align-items: center;

        @media (min-width: $screen-md) {
          grid-template-columns: repeat(3, 1fr);
          padding-top: 13px;
          width: 40%;
        }

        &__day-mobile {
          @media (min-width: $screen-sm) {
            display: none;
          }
        }

        &__day {
          display: none;

          @media (min-width: $screen-sm) {
            display: block;
          }
        }

        &__day,
        &__day-mobile {
          grid-column: 1;
          font-weight: 600;
        }

        &__status {
          grid-column: 2;

          @media (min-width: $screen-sm) {
            justify-self: center;
          }
        }

        &__h24 {
          grid-column: 3;

          @media (min-width: $screen-sm) {
            justify-self: center;
          }
        }
      }

      &__slots {
        @media (min-width: $screen-md) {
          width: 40%;
        }

        &__line {
          display: flex;
          align-items: flex-start;
          margin-top: $gutter-mobile / 2;

          @media (min-width: $screen-sm) {
            margin-top: $gutter-tablet / 2;
          }

          &:first-child {
            @media (min-width: $screen-md) {
              margin-top: 0;
            }
          }

          &__input {
            margin-right: $gutter-mobile / 2;
            width: calc(100% / 2 - (#{$button-min-height} / 2 + #{$gutter-mobile} / 2));

            @media (min-width: $screen-sm) {
              margin-right: $gutter-tablet / 2;
              width: calc(100% / 2 - (#{$button-min-height} / 2 + #{$gutter-tablet} / 2));
            }
          }
        }
      }
    }
  }

  &__subgroup {
    margin-top: $gutter-tablet;
    border-top: 1px solid var(--text-color-alt);
    padding: $gutter-tablet/2 $gutter-mobile 0;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-desktop;
      padding: $gutter-desktop/2 $gutter-tablet 0;
    }

    &:first-child {
      margin-top: 0;
      border: none;
    }

    &__top {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__name {
        flex: 1 1 auto;
      }

      &__default {
        margin-top: $gutter-mobile;
        margin-left: $gutter-mobile;
      }
    }
  }

  &__addbutton {
    padding-bottom: $gutter-mobile;

    @media (min-width: $screen-md) {
      padding-bottom: $gutter-tablet;
    }

    @media (min-width: $screen-xl) {
      padding-bottom: $gutter-desktop;
    }
  }
}

.screen-tabs {
  height: 51px;

  @media (min-width: $screen-md) {
    height: 54px;
  }

  &__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    &__container {
      position: sticky;
      top: -$gutter-mobile;
      z-index: 3;
      margin: (-$gutter-mobile) (-$gutter-mobile) 0 (-$gutter-mobile);
      background-color: var(--bg-color);
      width: calc(100% + (#{$gutter-mobile} * 2));

      @media (min-width: $screen-sm) {
        top: -$gutter-tablet;
        margin: (-$gutter-tablet) (-$gutter-tablet) 0 (-$gutter-tablet);
        width: calc(100% + (#{$gutter-tablet} * 2));
      }

      &__tab {
        display: inline-flex;
        position: relative;
        cursor: pointer;
        padding: $gutter-mobile;
        text-transform: uppercase;
        font-size: $font-size-sm;
        font-weight: 600;

        @media (min-width: $screen-sm) {
          padding: $gutter-mobile $gutter-tablet;
        }

        @media (min-width: $screen-md) {
          font-size: $font-size-default;
        }

        &:hover {
          background-color: var(--bg-color-hover);
        }

        &--selected {
          border-bottom: 4px solid;
          color: var(--product-color);
        }
      }
    }
  }
}
</style>
